<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <a href="#"> <i class="uil-home-alt"></i> </a>
          </li>
          <li>{{ $t('menu.company_dashboard.data') }}</li>
        </ul>
      </nav>
    </div>
    <div uk-grid>
      <div class="uk-width-1-3@m uk-flex-last@m">
        <nav class="responsive-tab style-3 setting-menu card"
          uk-sticky="top:30 ; offset:100; media:@m ;bottom:true; animation: uk-animation-slide-top">
          <ul>
            <li class="uk-active">
              <a href="#"><i class="uil-database-alt"></i> {{ $t('general.company_tag_data') }}</a>
            </li>
            <li class="">
              <a href="#"><i class="uil-money-stack"></i> Satın Alma Verileri</a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="uk-width-expand">
        <div class="uk-alert-success" uk-alert v-if="successMessage">
          <a class="uk-alert-close" uk-close></a>
          <p style="color: green">
            <i class="uil-exclamation-triangle"></i> {{ successMessage }}
          </p>
        </div>
        <div class="uk-alert-danger" uk-alert v-if="errorMessage">
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ errorMessage }}
          </p>
        </div>
        <!--Step 1-->
        <div class="card rounded">
          <div class="p-3 d-flex justify-content-between">
            <h4 class="mb-0">{{ $t('general.company_tag_data') }}</h4><br>
            <div>
              <div class="text-right mb-1">
                <button type="button" class="btn btn-success btn-sm mr-1" @click="is_excel = !is_excel"><i
                    :class="{ 'uil-plus': !is_excel, 'uil-times': is_excel }"></i> {{ $t('users.add_to_excel') }}
                </button>
              </div>
              <a class="mb-4" href="#" style="font-size: 14px; color: #ae71ff" @click="exportSampleFile"><i
                  class="uil-download-alt"></i> Şablon Excel Dokümanını İndir
                <i class="uil-info-circle" style="font-size: 1rem"
                  uk-tooltip="title: Şirket künyesini sisteme eklemek amacıyla şablon haline getirilmiş excel dokümanını indirin; pos: bottom-center"></i>
              </a>
            </div>
          </div>
          <div v-if="superAdmin()">
            <h5 style="margin-left: 1rem;" class="uk-text-bold mb-2"> {{ $t('users.institution') }}</h5>
            <multiselect style="width: 95%;margin-left: 1rem;" v-model="company_id" :allow-empty="false" :multiple="false"
              :options="formattedInstitution" :searchable="true" deselectLabel="" label="name" selectLabel="Seçiniz"
              :tag-placeholder="$t('general.select')" :placeholder="$t('general.select')" track-by="id"
              @input="changeCompany($event)">
              <template slot="option" slot-scope="option">
                {{ option.option.name }}
              </template>
            </multiselect>
          </div>
          <hr class="m-0" style="margin-top:1rem!important" />
          <div class="course-card-body">
            <form v-if="is_excel" action="api/something" enctype="multipart/form-data" method="post">
              <input id="fileToUpload" :disabled="is_loading" accept=".xls, .xlsx" name="fileToUpload" type="file"
                @change="setFile" @click="resetFileInfo" />
              <input class="btn btn-outline-primary btn-sm btn-block" name="uploadfile" type="button"
                :disabled="is_loading" value="Yükle" @click="excelToJSON" />
            </form>
            <hr v-if="is_excel" class="m-0" />

            <div class="pt-2 pb-1 text-center">
              <ul class="text-left data-accordion" uk-accordion="collapsible: true">
                <li class="pb-3">
                  <a class="uk-accordion-title" href="#"><i class="uil-check"></i> {{ $t('users.mission') }}</a>
                  <div class="uk-accordion-content" uk-grid>
                    <div class="uk-width-3-3@m">
                      <ul class="uk-list-divider">
                        <li class="
                            d-flex
                            justify-content-between
                            align-items-center
                          ">
                          <button class="btn btn-soft-primary btn-sm btn-block" href="#addModal" uk-toggle
                            @click="setModalType(typePosition)">
                            <i class="uil-plus"></i> Ekle
                          </button>
                        </li>
                        <li class="
                            d-flex
                            justify-content-between
                            align-items-center
                          " v-for="(item, index) in positions" :key="index">
                          <span><input class="uk-form-small mb-0" type="text" :value="item.name"
                              :id="typePosition + '_' + item.id" /></span>
                          <div>
                            <button class="btn btn-success btn-sm mr-1" type="button"
                              @click="updateItem(typePosition, item.id)">
                              <i class="uil-check"></i>
                            </button>
                            <button class="btn btn-primary btn-sm" type="button"
                              @click="deleteItem(typePosition, item.id)">
                              <i class="uil-times"></i> Sil
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="pb-3">
                  <a class="uk-accordion-title" href="#"><i class="uil-check"></i> {{ $t('users.title') }}</a>
                  <div class="uk-accordion-content" uk-grid>
                    <div class="uk-width-3-3@m">
                      <ul class="uk-list-divider">
                        <li class="
                            d-flex
                            justify-content-between
                            align-items-center
                          ">
                          <button class="btn btn-soft-primary btn-sm btn-block" href="#addModal" uk-toggle
                            @click="setModalType(typeTitle)">
                            <i class="uil-plus"></i> Ekle
                          </button>
                        </li>
                        <li class="
                            d-flex
                            justify-content-between
                            align-items-center
                          " v-for="(item, index) in titles" :key="index">
                          <span><input class="uk-form-small mb-0" type="text" :value="item.name"
                              :id="typeTitle + '_' + item.id" /></span>
                          <div>
                            <button class="btn btn-success btn-sm mr-1" type="button"
                              @click="updateItem(typeTitle, item.id)">
                              <i class="uil-check"></i>
                            </button>
                            <button class="btn btn-primary btn-sm" type="button" @click="deleteItem(typeTitle, item.id)">
                              <i class="uil-times"></i> Sil
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="pb-3">
                  <a class="uk-accordion-title" href="#"><i class="uil-check"></i> {{ $t('users.area') }}</a>
                  <div class="uk-accordion-content" uk-grid>
                    <div class="uk-width-3-3@m">
                      <ul class="uk-list-divider">
                        <li class="
                            d-flex
                            justify-content-between
                            align-items-center
                          ">
                          <button class="btn btn-soft-primary btn-sm btn-block" href="#addModal" uk-toggle
                            @click="setModalType(typeRegion)">
                            <i class="uil-plus"></i> Ekle
                          </button>
                        </li>
                        <li class="
                            d-flex
                            justify-content-between
                            align-items-center
                          " v-for="(item, index) in regions" :key="index">
                          <span><input class="uk-form-small mb-0" type="text" :value="item.name"
                              :id="typeRegion + '_' + item.id" /></span>
                          <div>
                            <button class="btn btn-success btn-sm mr-1" type="button"
                              @click="updateItem(typeRegion, item.id)">
                              <i class="uil-check"></i>
                            </button>
                            <button class="btn btn-primary btn-sm" type="button" @click="deleteItem(typeRegion, item.id)">
                              <i class="uil-times"></i> Sil
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="pb-3">
                  <a class="uk-accordion-title" href="#"><i class="uil-check"></i> {{ $t('users.department') }}</a>
                  <div class="uk-accordion-content" uk-grid>
                    <div class="uk-width-3-3@m">
                      <ul class="uk-list-divider">
                        <li class="
                            d-flex
                            justify-content-between
                            align-items-center
                          ">
                          <button class="btn btn-soft-primary btn-sm btn-block" href="#addModal" uk-toggle
                            @click="setModalType(typeDepartment)">
                            <i class="uil-plus"></i> Ekle
                          </button>
                        </li>
                        <li class="
                            d-flex
                            justify-content-between
                            align-items-center
                          " v-for="(item, index) in departments" :key="index">
                          <span><input class="uk-form-small mb-0" type="text" :value="item.name"
                              :id="typeDepartment + '_' + item.id" /></span>
                          <div>
                            <button class="btn btn-success btn-sm mr-1" type="button"
                              @click="updateItem(typeDepartment, item.id)">
                              <i class="uil-check"></i>
                            </button>
                            <button class="btn btn-primary btn-sm" type="button"
                              @click="deleteItem(typeDepartment, item.id)">
                              <i class="uil-times"></i> Sil
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li class="pb-3">
                  <a class="uk-accordion-title" href="#"><i class="uil-check"></i> {{ $t('users.branch') }}</a>
                  <div class="uk-accordion-content" uk-grid>
                    <div class="uk-width-3-3@m">
                      <ul class="uk-list-divider">
                        <li class="
                            d-flex
                            justify-content-between
                            align-items-center
                          ">
                          <button class="btn btn-soft-primary btn-sm btn-block" href="#addModal" uk-toggle
                            @click="setModalType(typeBranch)">
                            <i class="uil-plus"></i> Ekle
                          </button>
                        </li>
                        <li class="
                            d-flex
                            justify-content-between
                            align-items-center
                          " v-for="(item, index) in branchs" :key="index">
                          <span><input class="uk-form-small mb-0" type="text" :value="item.name"
                              :id="typeBranch + '_' + item.id" /></span>
                          <div>
                            <button class="btn btn-success btn-sm mr-1" type="button"
                              @click="updateItem(typeBranch, item.id)">
                              <i class="uil-check"></i>
                            </button>
                            <button class="btn btn-primary btn-sm" type="button" @click="deleteItem(typeBranch, item.id)">
                              <i class="uil-times"></i> Sil
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--END Step 1-->

        <!--Step 2-->
        <div class="card rounded">
          <div class="p-3 d-flex justify-content-between">
            <h4 class="mb-0">Satın Alma Verileri</h4><br>
          </div>
          <hr class="m-0" style="margin-top:1rem!important" />
          <div class="course-card-body">
            <div class="pt-2 pb-1 text-center">
              <ul class="text-left data-accordion" uk-accordion="collapsible: true">
                <li class="pb-3">
                  <a class="uk-accordion-title" href="#"><i class="uil-check"></i> Para Birimleri</a>
                  <div class="uk-accordion-content" uk-grid>
                    <div class="uk-width-3-3@m">
                      <ul class="uk-list-divider">
                        <li class="
                            d-flex
                            justify-content-between
                            align-items-center
                          ">
                          <button class="btn btn-soft-primary btn-sm btn-block" href="#addModal2" uk-toggle
                            @click="setModalType(typePosition)">
                            <i class="uil-plus"></i> Ekle
                          </button>
                        </li>
                        <li class="row align-items-center">
                          <div class="col-md-5">
                            <label class="col-form-label"><small>Para Birimi Adı</small></label>
                          </div>
                          <div class="col-md-2">
                            <label class="col-form-label"><small>Kodu</small></label>
                          </div>
                          <div class="col-md-2">
                            <label class="col-form-label"><small>Sembolü</small></label>
                          </div>
                          <div class="col-md-3"></div>
                        </li>
                        <li class="row align-items-center" v-for="currency in currencies">
                          <div class="col-md-5">
                            <input class="uk-form-small mb-0" type="text" :value="currency.name" />
                          </div>
                          <div class="col-md-2">
                            <input class="uk-form-small mb-0" type="text" :value="currency.code"  />
                          </div>
                          <div class="col-md-2">
                            <input class="uk-form-small mb-0" type="text" :value="currency.symbol"  />
                          </div>
                          <div class="col-md-3"> <button class="btn btn-success btn-sm mr-1" type="button"
                              @click="updateItem(typePosition, item.id)">
                              <i class="uil-check"></i>
                            </button>
                            <button class="btn btn-primary btn-sm" type="button"
                              @click="deleteItem(typePosition, item.id)">
                              <i class="uil-times"></i> Sil
                            </button>
                          </div>
                          <div class="d-none">
                            <button class="btn btn-success btn-sm mr-1" type="button"
                              @click="updateItem(typePosition, item.id)">
                              <i class="uil-check"></i>
                            </button>
                            <button class="btn btn-primary btn-sm" type="button"
                              @click="deleteItem(typePosition, item.id)">
                              <i class="uil-times"></i> Sil
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </div>
        <!--END Step 2-->
      </div>
    </div>

    <!--Step 1 Modal-->
    <div id="addModal" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Ekle</h2>
        </div>
        <div class="uk-modal-body">
          <input type="hidden" id="type" v-model="selected_type" />
          <div v-if="errorMessages" class="uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color: red">
              <i class="uil-exclamation-triangle"></i> {{ errorMessages }}
            </p>
          </div>
          <div class="form-group row mb-3">
            <label class="col-md-3 col-form-label" for="course_title">Veri Ekle <span class="required">*</span></label>
            <div class="col-md-9">
              <input type="text" class="form-control" id="course_title" name="title" placeholder=""
                v-model="selected_text" required="" />
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close" type="button">
            Vazgeç
          </button>
          <button class="uk-button uk-button-primary ml-2" type="button" @click="addItem()">
            {{ $t("general.save") }}
          </button>
        </div>
      </div>
    </div>

    <!--Step 2 Modal-->
    <div id="addModal2" uk-modal>
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Para Birimi Ekle</h2>
        </div>
        <div class="uk-modal-body">
          <input type="hidden" id="type" v-model="selected_type" />
          <div v-if="errorMessages" class="uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color: red">
              <i class="uil-exclamation-triangle"></i> {{ errorMessages }}
            </p>
          </div>
          <div class="form-group row mb-3">
            <div class="col-md-4">
              <label class="col-form-label" for="currency_name">Para Birimi Adı<span class="required">*</span></label>
              <input type="text" class="form-control" id="currency_name" name="currency_name" placeholder=""
                required="" />
            </div>
            <div class="col-md-4">
              <label class="col-form-label" for="currency_code">Para Birimi Kodu<span class="required">*</span></label>
              <input type="text" class="form-control" id="currency_code" name="currency_code" placeholder=""
                required="" />
            </div>
            <div class="col-md-4">
              <label class="col-form-label" for="currency_symbol">Para Birimi Sembolü<span
                  class="required">*</span></label>
              <input type="text" class="form-control" id="currency_symbol" name="currency_symbol" placeholder=""
                required="" />
            </div>

          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close" type="button">
            Vazgeç
          </button>
          <button class="uk-button uk-button-primary ml-2" type="button" @click="addItem()">
            {{ $t("general.save") }}
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from "vue";
import DataTable from "@/assets/components/dataTable/DataTable";
import store from "@/core/services/index";
import moduleRegion, {
  BASE_URL as REGION_BASE_URL,
  GET_ITEM as REGION_ITEM,
  GET_ITEMS as GET_REGION_ITEMS,
  UPDATE_ITEM as REGION_UPDATE_ITEM,
  DELETE_ITEM as REGION_DELETE_ITEM,
  CREATE_ITEM as REGION_CREATE_ITEM,
  MODULE_NAME as REGION_MODULE_NAME,
  ITEMS as REGION_ITEMS,
  ERROR as REGION_ERROR,
} from "@/core/services/store/region.module";

import moduleBranch, {
  BASE_URL as BRANCH_BASE_URL,
  GET_ITEM as BRANCH_ITEM,
  GET_ITEMS as GET_BRANCH_ITEMS,
  UPDATE_ITEM as BRANCH_UPDATE_ITEM,
  DELETE_ITEM as BRANCH_DELETE_ITEM,
  CREATE_ITEM as BRANCH_CREATE_ITEM,
  MODULE_NAME as BRANCH_MODULE_NAME,
  ITEMS as BRANCH_ITEMS,
  ERROR as BRANCH_ERROR,
} from "@/core/services/store/branch.module";

import modulePosition, {
  BASE_URL as POSITION_BASE_URL,
  GET_ITEM as POSITION_ITEM,
  GET_ITEMS as GET_POSITION_ITEMS,
  UPDATE_ITEM as POSITION_UPDATE_ITEM,
  DELETE_ITEM as POSITION_DELETE_ITEM,
  CREATE_ITEM as POSITION_CREATE_ITEM,
  MODULE_NAME as POSITION_MODULE_NAME,
  ITEMS as POSITION_ITEMS,
  ERROR as POSITION_ERROR,
} from "@/core/services/store/position.module";

import moduleTitle, {
  BASE_URL as TITLE_BASE_URL,
  GET_ITEM as TITLE_ITEM,
  GET_ITEMS as GET_TITLE_ITEMS,
  UPDATE_ITEM as TITLE_UPDATE_ITEM,
  DELETE_ITEM as TITLE_DELETE_ITEM,
  CREATE_ITEM as TITLE_CREATE_ITEM,
  MODULE_NAME as TITLE_MODULE_NAME,
  ITEMS as TITLE_ITEMS,
  ERROR as TITLE_ERROR,
} from "@/core/services/store/title.module";

import moduleStore, {
  BASE_URL as STORE_BASE_URL,
  GET_ITEM as STORE_ITEM,
  GET_ITEMS as GET_STORE_ITEMS,
  UPDATE_ITEM as STORE_UPDATE_ITEM,
  DELETE_ITEM as STORE_DELETE_ITEM,
  CREATE_ITEM as STORE_CREATE_ITEM,
  MODULE_NAME as STORE_MODULE_NAME,
  ITEMS as STORE_ITEMS,
  ERROR as STORE_ERROR,
} from "@/core/services/store/store.module";

import moduleDepartment, {
  BASE_URL as DEPARTMENT_BASE_URL,
  GET_ITEM as DEPARTMENT_ITEM,
  GET_ITEMS as GET_DEPARTMENT_ITEMS,
  UPDATE_ITEM as DEPARTMENT_UPDATE_ITEM,
  DELETE_ITEM as DEPARTMENT_DELETE_ITEM,
  CREATE_ITEM as DEPARTMENT_CREATE_ITEM,
  MODULE_NAME as DEPARTMENT_MODULE_NAME,
  ITEMS as DEPARTMENT_ITEMS,
  ERROR as DEPARTMENT_ERROR,
} from "@/core/services/store/department.module";


import moduleData, {
  BASE_URL as DATA_BASE_URL,
  IMPORT as DATA_IMPORT,
  MODULE_NAME as DATA_MODULE_NAME,
  ERROR as DATA_ERROR,

} from "@/core/services/store/data.module";
import company_module, {
  BASE_URL as COMPANY_BASE_URL,
  GET_ITEMS as GET_INSTITUTIONS,
  ITEMS as INSTITUTIONS,
  MODULE_NAME as COMPANY_MODULE,
} from "@/core/services/store/company.module";

import readXlsxFile from "read-excel-file";
import axios from "axios";
import moment from "moment/moment";
const _COMPANY_MODULE_NAME = COMPANY_MODULE;
export default {
  name: "Data",
  components: { DataTable },
  data() {
    return {
      currencies: [],
      company_id: null,
      typeRegion: "region",
      typeBranch: "branch",
      typePosition: "position",
      typeTitle: "title",
      typeDepartment: "department",
      typeStore: "store",
      selected_type: null,
      selected_text: null,
      is_excel: false,
      successMessage: null,
      errorMessages: null,
      errorMessage: null,
      file: null,
      file_value: null,
      fileInfo: {
        name: "",
        count: 0,
        file: null,
      },
      is_added: false,
      is_loading: false,
      excel_array: []
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(REGION_MODULE_NAME, moduleRegion);
    registerStoreModule(BRANCH_MODULE_NAME, moduleBranch);
    registerStoreModule(POSITION_MODULE_NAME, modulePosition);
    registerStoreModule(TITLE_MODULE_NAME, moduleTitle);
    registerStoreModule(STORE_MODULE_NAME, moduleStore);
    registerStoreModule(DEPARTMENT_MODULE_NAME, moduleDepartment);
    registerStoreModule(DATA_MODULE_NAME, moduleData);
    registerStoreModule(_COMPANY_MODULE_NAME, company_module)
  },
  computed: {
    userInfo: {
      get: function () {
        return JSON.parse(this.secureLocalStorage("user"));
      },
      set: function (newValue) {
      },
    },
    institutions: {
      get() {
        return store.getters[COMPANY_MODULE + "/" + INSTITUTIONS];
      },
      set(value) {
      },
    },
    formattedInstitution() {
      let results = [];
      if (this.institutions === null || !Array.isArray(this.institutions)) return [];
      this.institutions.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
        });
      });
      return results;
    },
    user_header: {
      get() {
        return JSON.parse(this.secureLocalStorage("user"));
      },
      set() { },
    },
    regions: {
      get() {
        return store.getters[REGION_MODULE_NAME + "/" + REGION_ITEMS];
      },
      set(value) {
      },
    },
    regionError: {
      get() {
        return store.getters[REGION_MODULE_NAME + "/" + REGION_ERROR];
      },
      set(value) {
      },
    },
    branchs: {
      get() {
        return store.getters[BRANCH_MODULE_NAME + "/" + BRANCH_ITEMS];
      },
      set(value) {
      },
    },
    branchError: {
      get() {
        return store.getters[BRANCH_MODULE_NAME + "/" + BRANCH_ERROR];
      },
      set(value) {
      },
    },
    positions: {
      get() {
        return store.getters[POSITION_MODULE_NAME + "/" + POSITION_ITEMS];
      },
      set(value) {
      },
    },
    positionError: {
      get() {
        return store.getters[POSITION_MODULE_NAME + "/" + POSITION_ERROR];
      },
      set(value) {
      },
    },
    titles: {
      get() {
        return store.getters[TITLE_MODULE_NAME + "/" + TITLE_ITEMS];
      },
      set(value) {
      },
    },
    titleError: {
      get() {
        return store.getters[TITLE_MODULE_NAME + "/" + TITLE_ERROR];
      },
      set(value) {
      },
    },
    dataError: {
      get() {
        return store.getters[DATA_MODULE_NAME + "/" + DATA_ERROR];
      },
      set(value) {
      },
    },
    departments: {
      get() {
        return store.getters[DEPARTMENT_MODULE_NAME + "/" + DEPARTMENT_ITEMS];
      },
      set(value) {
      },
    },
    departmentError: {
      get() {
        return store.getters[DEPARTMENT_MODULE_NAME + "/" + DEPARTMENT_ERROR];
      },
      set(value) {
      },
    },
    stores: {
      get() {
        return store.getters[STORE_MODULE_NAME + "/" + STORE_ITEMS];
      },
      set(value) {
      },
    },
    storeError: {
      get() {
        return store.getters[STORE_MODULE_NAME + "/" + STORE_ERROR];
      },
      set(value) {
      },
    },
  },
  methods: {
    getCurrency() {
      axios.get(`${'api/currencies'}`, {
        headers: this.headers
      }).then((result) => {
        console.log(result);
        this.currencies = result.data;
        debugger
      })
    },
    getValues() {
      if (!this.company_id) {
        this.company_id = {
          id: this.userInfo.company_id, name: this.userInfo.company_name
        }
      }
      if (this.company_id) {
        this.regionResult();
        this.branchResult();
        this.positionResult();
        this.titleResult();
        this.departmentResult();
        this.getCurrency();
        this.superAdmin() ? this.getInstitutions() : null
      }
    },
    changeCompany($event) {
      this.company_id = $event;
      this.getValues();
    },
    getInstitutions() {
      this.$store.dispatch(COMPANY_MODULE + '/' + GET_INSTITUTIONS, {
        url: COMPANY_BASE_URL,
        filters: {
          all: "all",
          sort: 'asc',
          order: 'id',
        }
      });
    },
    superAdmin() {
      if (this.user_header === null || !("roles" in this.user_header) || this.user_header.roles.length < 3) {
        return false;
      } else
        return true
    },
    showAddModal(type) {
    },
    regionResult() {
      this.$store.dispatch(REGION_MODULE_NAME + "/" + GET_REGION_ITEMS, {
        url: REGION_BASE_URL,
        filters: {
          company_id: this.company_id.id
        }
      });
    },
    branchResult() {
      this.$store.dispatch(BRANCH_MODULE_NAME + "/" + GET_BRANCH_ITEMS, {
        url: BRANCH_BASE_URL,
        filters: {
          company_id: this.company_id.id
        }
      });
    },
    positionResult() {
      this.$store.dispatch(POSITION_MODULE_NAME + "/" + GET_POSITION_ITEMS, {
        url: POSITION_BASE_URL,
        filters: {
          company_id: this.company_id.id
        }
      });
    },
    titleResult() {
      this.$store.dispatch(TITLE_MODULE_NAME + "/" + GET_TITLE_ITEMS, {
        url: TITLE_BASE_URL,
        filters: {
          company_id: this.company_id.id
        }
      });
    },
    // storeResult() {
    //   this.$store.dispatch(STORE_MODULE_NAME + "/" + GET_STORE_ITEMS, {
    //     url: STORE_BASE_URL,
    //   });
    // },
    departmentResult() {
      this.$store.dispatch(
        DEPARTMENT_MODULE_NAME + "/" + GET_DEPARTMENT_ITEMS,
        {
          url: DEPARTMENT_BASE_URL,
        }
      );
    },
    setModalType(type) {
      this.selected_text = null;
      this.selected_type = type;
    },
    closeModal() {
      this.selected_text = null;
      this.selected_type = null;
      UIkit.modal("#addModal").hide();
    },
    resetValues() {
      this.errorMessage = null;
      this.successMessage = null;
      this.errorMessages = null;
    },
    addItem() {
      let self = this;
      if (self.selected_text && self.selected_type) {
        let content = new FormData();
        content.append("status", 1);
        content.append("name", self.selected_text);
        if (self.selected_type === self.typeRegion) {
          this.$store
            .dispatch(REGION_MODULE_NAME + "/" + REGION_CREATE_ITEM, {
              url: REGION_BASE_URL + '?company_id=' + this.company_id.id,
              contents: content,
            })
            .then(() => {
              if (!self.regionError) {
                self.closeModal();
                self.successMessage = self.$t("general.create_success");
                this.regionResult();
              }
            });
        }

        if (self.selected_type === self.typeBranch) {
          this.$store
            .dispatch(BRANCH_MODULE_NAME + "/" + BRANCH_CREATE_ITEM, {
              url: BRANCH_BASE_URL + '?company_id=' + this.company_id.id,
              contents: content,
            })
            .then(() => {
              if (!self.branchError) {
                self.closeModal();
                self.successMessage = self.$t("general.create_success");
                this.branchResult();
              }
            });
        }

        if (self.selected_type === self.typePosition) {
          this.$store
            .dispatch(POSITION_MODULE_NAME + "/" + POSITION_CREATE_ITEM, {
              url: POSITION_BASE_URL + '?company_id=' + this.company_id.id,
              contents: content,
            })
            .then(() => {
              if (!self.positionError) {
                self.closeModal();
                self.successMessage = self.$t("general.create_success");
                this.positionResult();
              }
            });
        }
        if (self.selected_type === self.typeTitle) {
          this.$store
            .dispatch(TITLE_MODULE_NAME + "/" + TITLE_CREATE_ITEM, {
              url: TITLE_BASE_URL + '?company_id=' + this.company_id.id,
              contents: content,
            })
            .then(() => {
              if (!self.titleError) {
                self.closeModal();
                self.successMessage = self.$t("general.create_success");
                this.titleResult();
              }
            });
        }

        // if (self.selected_type === self.typeStore) {
        //   this.$store
        //     .dispatch(STORE_MODULE_NAME + "/" + STORE_CREATE_ITEM, {
        //       url: STORE_BASE_URL,
        //       contents: content,
        //     })
        //     .then(() => {
        //       if (!self.storeError) {
        //         self.closeModal();
        //         self.successMessage = self.$t("general.create_success");
        //         this.storeResult();
        //       }
        //     });
        // }

        if (self.selected_type === self.typeDepartment) {
          this.$store
            .dispatch(DEPARTMENT_MODULE_NAME + "/" + DEPARTMENT_CREATE_ITEM, {
              url: DEPARTMENT_BASE_URL + '?company_id=' + this.company_id.id,
              contents: content,
            })
            .then(() => {
              if (!self.storeError) {
                self.closeModal();
                self.successMessage = self.$t("general.create_success");
                this.departmentResult();
              }
            });
        }
      } else self.errorMessages = self.$t("general.required.name");
      return false;
    },
    updateItem(type, itemId) {
      let self = this;
      let input = "input#" + type + "_" + itemId;
      let value = $(input).val();
      if (value) {
        let content = {
          name: value,
          status: 1,
        };
        if (type === self.typeRegion) {
          this.$store
            .dispatch(REGION_MODULE_NAME + "/" + REGION_UPDATE_ITEM, {
              url: REGION_BASE_URL + "/" + itemId + '?company_id=' + this.company_id.id,
              contents: content,
            })
            .then(() => {
              if (!self.regionError) {
                self.successMessage = self.$t("general.update_success");
                this.regionResult();
              }
            });
        }

        if (type === self.typeBranch) {
          this.$store
            .dispatch(BRANCH_MODULE_NAME + "/" + BRANCH_UPDATE_ITEM, {
              url: BRANCH_BASE_URL + "/" + itemId + '?company_id=' + this.company_id.id,
              contents: content,
            })
            .then(() => {
              if (!self.branchError) {
                self.successMessage = self.$t("general.update_success");
                this.branchResult();
              }
            });
        }

        if (type === self.typePosition) {
          this.$store
            .dispatch(POSITION_MODULE_NAME + "/" + POSITION_UPDATE_ITEM, {
              url: POSITION_BASE_URL + "/" + itemId + '?company_id=' + this.company_id.id,
              contents: content,
            })
            .then(() => {
              if (!self.positionError) {
                self.successMessage = self.$t("general.update_success");
                this.positionResult();
              }
            });
        }
        if (type === self.typeTitle) {
          this.$store
            .dispatch(TITLE_MODULE_NAME + "/" + TITLE_UPDATE_ITEM, {
              url: TITLE_BASE_URL + "/" + itemId + '?company_id=' + this.company_id.id,
              contents: content,
            })
            .then(() => {
              if (!self.titleError) {
                self.successMessage = self.$t("general.update_success");
                this.titleResult();
              }
            });
        }

        // if (type === self.typeStore) {
        //   this.$store
        //     .dispatch(STORE_MODULE_NAME + "/" + STORE_UPDATE_ITEM, {
        //       url: STORE_BASE_URL + "/" + itemId,
        //       contents: content,
        //     })
        //     .then(() => {
        //       if (!self.storeError) {
        //         self.successMessage = self.$t("general.update_success");
        //         this.storeResult();
        //       }
        //     });
        // }

        if (type === self.typeDepartment) {
          this.$store
            .dispatch(DEPARTMENT_MODULE_NAME + "/" + DEPARTMENT_UPDATE_ITEM, {
              url: DEPARTMENT_BASE_URL + "/" + itemId + '?company_id=' + this.company_id.id,
              contents: content,
            })
            .then(() => {
              if (!self.storeError) {
                self.successMessage = self.$t("general.update_success");
                this.departmentResult();
              }
            });
        }
      } else self.errorMessage = self.$t("general.required.name");
      return false;
    },
    deleteItem(type, itemId) {
      let self = this;
      let result = confirm("Silmek istediğinizden emin misiniz?");
      if (result) {
        if (type === this.typeRegion) {
          this.$store
            .dispatch(REGION_MODULE_NAME + "/" + REGION_DELETE_ITEM, {
              url: REGION_BASE_URL + "/" + itemId + '?company_id=' + this.company_id.id,
            })
            .then(() => {
              if (!self.regionError) {
                this.regionResult();
                self.successMessage = self.$t("general.successfully_deleted");
              }
            });
        }

        if (type === this.typeBranch) {
          this.$store
            .dispatch(BRANCH_MODULE_NAME + "/" + BRANCH_DELETE_ITEM, {
              url: BRANCH_BASE_URL + "/" + itemId + '?company_id=' + this.company_id.id,
            })
            .then(() => {
              if (!self.branchError) {
                this.branchResult();
                self.successMessage = self.$t("general.successfully_deleted");
              }
            });
        }

        if (type === this.typePosition) {
          this.$store
            .dispatch(POSITION_MODULE_NAME + "/" + POSITION_DELETE_ITEM, {
              url: POSITION_BASE_URL + "/" + itemId + '?company_id=' + this.company_id.id,
            })
            .then(() => {
              if (!self.positionError) {
                this.positionResult();
                self.successMessage = self.$t("general.successfully_deleted");
              }
            });
        }

        if (type === this.typeTitle) {
          this.$store
            .dispatch(TITLE_MODULE_NAME + "/" + TITLE_DELETE_ITEM, {
              url: TITLE_BASE_URL + "/" + itemId + '?company_id=' + this.company_id.id,
            })
            .then(() => {
              if (!self.titleError) {
                this.titleResult();
                self.successMessage = self.$t("general.successfully_deleted");
              }
            });
        }

        // if (type === this.typeStore) {
        //   this.$store
        //     .dispatch(STORE_MODULE_NAME + "/" + STORE_DELETE_ITEM, {
        //       url: STORE_BASE_URL + "/" + itemId,
        //     })
        //     .then(() => {
        //       if (!self.storeError) {
        //         this.storeResult();
        //         self.successMessage = self.$t("general.successfully_deleted");
        //       }
        //     });
        // }

        if (type === this.typeDepartment) {
          this.$store
            .dispatch(DEPARTMENT_MODULE_NAME + "/" + DEPARTMENT_DELETE_ITEM, {
              url: DEPARTMENT_BASE_URL + "/" + itemId + '?company_id=' + this.company_id.id,
            })
            .then(() => {
              if (!self.storeError) {
                this.departmentResult();
                self.successMessage = self.$t("general.successfully_deleted");
              }
            });
        }
      }
      return false;
    },
    resetFileInfo() {
      this.fileInfo.file = null;
      this.excel_array = [];
      $("#fileToUpload").val("");
    },
    setFile(e) {
      this.file_value = e.target.value
      this.file = e.target.files[0];
    },

    excelToJSON(e) {
      if (this.file) {
        let self = this;
        this.is_loading = true
        this.fileInfo.file = this.file;
        readXlsxFile(this.file).then((rows) => {
          delete rows[0];
          self.excel_array = [];

          rows.forEach(function (row) {

            let position_name = row[0];
            let title_name = row[1];
            let region_name = row[2];
            let department_name = row[3];
            let branch_name = row[4];
            // let store_name = row[5];

            if (position_name)
              self.excel_array.push({ 'type': 'position', 'name': position_name });
            if (title_name)
              self.excel_array.push({ 'type': 'title', 'name': title_name });
            if (region_name)
              self.excel_array.push({ 'type': 'region', 'name': region_name });
            if (department_name)
              self.excel_array.push({ 'type': 'department', 'name': department_name });
            if (branch_name)
              self.excel_array.push({ 'type': 'branch', 'name': branch_name });
            // if (store_name)
            //   self.excel_array.push({ 'type': 'store', 'name': store_name });
          });

          if (self.excel_array.length) {
            this.$store
              .dispatch(DATA_MODULE_NAME + "/" + DATA_IMPORT, {
                url: DATA_BASE_URL + "/excel-import",
                contents: { data: self.excel_array },
              })
              .then(() => {

                if (!self.dataError) {
                  this.regionResult();
                  this.branchResult();
                  this.positionResult();
                  this.titleResult();
                  this.departmentResult();
                  // this.storeResult();
                  self.is_loading = false
                  self.is_excel = false;
                  self.excel_array = [];
                  self.successMessage = self.$t("general.create_success");
                } else {
                  self.errorMessage = self.dataError;
                }
              });
          } else {
            self.errorMessage = self.$t("Liste boş lütfen kontrol edip tekrar deneyin.");
            self.is_loading = false

          }
        });

      }
    },
    exportSampleFile() {
      let config = {
        responseType: 'blob',
        headers: this.apiHeaders,
      };
      axios.post(process.env.VUE_APP_BACKEND_APP_URL + '/api/data/export-template', [], config)
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement('a');

          fileLink.href = fileURL;
          let day = moment().format('YYYYMMDDHHMMSS');
          let fileName = 'company_tag_sample_' + day + '.xlsx';
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch((error) => {
        })
    },
  },
  watch: {
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
    errorMessage: function (val) {
      this.debouncedResetValues();
    },
  },
  mounted() {
    this.getValues();
  },
  created() {
    this.debouncedResetValues = _.debounce(this.resetValues, 1500);
  },
};
</script>
<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>

<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
